@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles.css';

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #2828f0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  padding: 9px;
  border: 1px solid #2828f0;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #2828f0;
}

.custom-checkbox:checked::after {
  content: '\2713';
  font-size: 14px;
  color: #ffffff !important;
  position: absolute;
  top: 0;
  left: 2px;
}

.no-scroll {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
