@font-face {
  font-family: 'Fieldwork';
  src: url('./Assets/fonts/Fieldwork5HumThin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fieldwork';
  src: url('./Assets/fonts/Fieldwork13SemiBoldGeo.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

.poppins-100 {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-200 {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-300 {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-400 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-500 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-600 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-700 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-800 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: italic;
}

roboto-100 {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-300 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-400 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-500 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-700 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-900 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
}
